import dock from "assets/images/dock.png";
import fbn from "assets/images/fbn_avi.png";

interface Recommendation {
  name: string;
  content: string;
  company: string;
  position: string;
  image: string;
}

export const recommendations: Recommendation[] = [
  {
    name: "SEGUN OLANITORI",
    content:
      "I have worked with Erastus on hobby and serious projects, I’m impressed by his thorough design processes and attention to details. He is an all round product person.",
    company: "Dock",
    position: "Fullstack Developer",
    image: dock,
  },
  {
    name: "OKWUZI EMMANUEL",
    content:
      "Erastus knows his stuff, but is open to criticism and a good team player. His designs are innovative, modern and aesthetically pleasing.",
    company: "FirstBank of Nigeria",
    position: "Fullstack Developer",
    image: fbn,
  },
  {
    name: "ODOFIN DAMOLA",
    content:
      "Erastus is one of the Product Designers I have worked with that actually really cares about the users experience and takes careful steps to finding a solution to problem (s). It's very easy to get lost and design based on your biases and assumptions, but he is clear headed and professional enough to separate these and validate and research on the users before working on anything. It was a good time working with Erastus and I learnt one or two things working with him as well. It also helps that he is a funny person so it was never a dull time working with him.",
    company: "FirstBank of Nigeria",
    position: "Product Designer",
    image: fbn,
  },
];
