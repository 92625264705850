import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import initial from "assets/icons/E.O_black.svg";
import download from "assets/icons/download.svg";
import close from "assets/icons/close.svg";
import hamburger from "assets/images/hamburger.png";
import classes from "./header.module.css";
import { MouseContext } from "contexts/mouseContext";

const activeNavStyle = {
  color: "black",
  fontWeight: "600",
};

export const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { cursorChangeHandler } = useContext(MouseContext);

  return (
    <Flex
      as="header"
      pos="fixed"
      w="100vw"
      align="center"
      py="25px"
      px={{ base: "20px", md: "90px" }}
      bg="rgba(255, 255, 255, 0.7)"
      backdropFilter="blur(8px)"
      borderBottom="1px solid #DFDFDF"
      zIndex="3"
    >
      <NavLink to="/">
        <img src={initial} alt="initial" />
      </NavLink>

      <Flex
        display={{ base: "none", md: "flex" }}
        as="ul"
        style={{ listStyle: "none" }}
        ml="auto"
      >
        <Box
          onMouseEnter={() => cursorChangeHandler("hover")}
          onMouseLeave={() => cursorChangeHandler("")}
        >
          <NavLink
            to="/"
            end
            style={({ isActive }) => (isActive ? activeNavStyle : undefined)}
            className={classes.link}
          >
            <Text
              as="li"
              mr="20px"
              onMouseEnter={() => cursorChangeHandler("hover")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              Home
            </Text>
          </NavLink>
        </Box>

        <Box
          onMouseEnter={() => cursorChangeHandler("hover")}
          onMouseLeave={() => cursorChangeHandler("")}
        >
          <NavLink
            to="projects"
            style={({ isActive }) => (isActive ? activeNavStyle : undefined)}
            className={classes.link}
          >
            <Text as="li" mr="20px">
              Projects
            </Text>
          </NavLink>
        </Box>

        <Box
          onMouseEnter={() => cursorChangeHandler("hover")}
          onMouseLeave={() => cursorChangeHandler("")}
        >
          <NavLink
            to="about"
            style={({ isActive }) => (isActive ? activeNavStyle : undefined)}
            className={classes.link}
          >
            <Text as="li">About</Text>
          </NavLink>
        </Box>
      </Flex>

      <Flex display={{ base: "none", md: "flex" }} ml="auto" align="center">
        <Link
          href="https://docs.google.com/document/d/1XDdYCkqQJzKW7r7JagN2tHqpbj3dn5llzV66LxLBHA4/edit?usp=sharing"
          target="_blank"
          fontWeight="600"
        >
          View CV
        </Link>
        <Box ml="10px">
          <img src={download} alt="Download" />
        </Box>
      </Flex>

      <Flex display={{ base: "flex", md: "none" }} ml="auto">
        <Button
          onClick={onOpen}
          variant="ghost"
          p="0"
          _hover={{ bg: "transparent" }}
        >
          <img src={hamburger} alt="Hamburger" />
        </Button>
      </Flex>

      <Drawer isOpen={isOpen} placement="top" onClose={onClose} size="full">
        <DrawerContent>
          <DrawerBody p="0">
            <Flex
              justify="space-between"
              p="20px"
              borderBottom="1px solid #DFDFDF"
            >
              <NavLink to="/">
                <img src={initial} alt="initial" />
              </NavLink>

              <Button
                variant="ghost"
                onClick={onClose}
                _active={{ bg: "transparent" }}
              >
                <img src={close} alt="close" />
              </Button>
            </Flex>

            <Flex direction="column" h="90vh" py="100px">
              <Flex
                as="ul"
                direction="column"
                align="center"
                style={{ listStyle: "none" }}
                w="full"
              >
                <NavLink
                  to="/"
                  end
                  style={({ isActive }) =>
                    isActive ? activeNavStyle : undefined
                  }
                  className={classes.link}
                  onClick={onClose}
                >
                  <Text as="li" mb="50px">
                    Home
                  </Text>
                </NavLink>

                <NavLink
                  to="projects"
                  style={({ isActive }) =>
                    isActive ? activeNavStyle : undefined
                  }
                  className={classes.link}
                  onClick={onClose}
                >
                  <Text as="li" mb="50px">
                    Projects
                  </Text>
                </NavLink>

                <NavLink
                  to="about"
                  style={({ isActive }) =>
                    isActive ? activeNavStyle : undefined
                  }
                  className={classes.link}
                  onClick={onClose}
                >
                  <Text as="li">About</Text>
                </NavLink>
              </Flex>

              <Link
                href="https://docs.google.com/document/d/1XDdYCkqQJzKW7r7JagN2tHqpbj3dn5llzV66LxLBHA4/edit?usp=sharing"
                target="_blank"
                mt="auto"
                mb="20px"
                textDecoration="none"
                _hover={{ textDecor: "none" }}
              >
                <Box
                  w="218px"
                  mx="auto"
                  bg="transparent"
                  border="1px solid black"
                  borderRadius="5px"
                  fontWeight="600"
                  textAlign="center"
                >
                  View CV
                </Box>
              </Link>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
