import { useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import ibm from "assets/images/ibm.png";
import utiva from "assets/images/utiva.png";
import digital_skills from "assets/images/digital_skills.png";
import google from "assets/images/google_certificate.png";
import erastus from "assets/images/erastus.png";
import name_preference from "assets/icons/name_preference_tag.svg";
import { Companies } from "components/Companies";

type Certificates = "google" | "utiva" | "ibm" | "dSkills";

export const About = () => {
  const [image, setImage] = useState<Certificates>("google");
  const [show, setShow] = useState(false);
  const companiesRef = useRef<HTMLDivElement | null>(null);

  const imagePath = (): string => {
    switch (image) {
      case "google":
        return google;
      case "ibm":
        return ibm;
      case "utiva":
        return utiva;
      case "dSkills":
        return digital_skills;
      default:
        return google;
    }
  };
  const onViewImage = (image: Certificates) => {
    setImage(image);
    setShow(true);
  };
  return (
    <Flex as="section" direction="column" pt="50px">
      <Flex direction="column" mx={{ base: "30px", lg: "50px", xl: "180px" }}>
        <Flex mr="auto" ml={{ base: "auto", lg: "0" }} mb="50px" align="center">
          <Text as="span" fontWeight="700">
            About Erastus
          </Text>
          <Button
            variant="ghost"
            fontWeight="normal"
            color="era.brown"
            onClick={() => companiesRef.current?.scrollIntoView()}
          >
            Companies
          </Button>
        </Flex>

        <Flex
          justify="space-between"
          mb="50px"
          direction={{ base: "column-reverse", lg: "row" }}
          pt={{ base: "160px", lg: "0" }}
        >
          <Box>
            <Box
              w={{ base: "auto", lg: "600px" }}
              mr="auto"
              mb="60px"
              pt={{ base: "90px", lg: "0" }}
            >
              <Text
                fontSize="32px"
                fontWeight="700"
                mb="25px"
                pos="relative"
                textAlign={{ base: "center", lg: "initial" }}
              >
                <Box
                  as="span"
                  pos="absolute"
                  zIndex="-1"
                  top={{ base: "16px", md: "-30px", lg: "-30px" }}
                  right={{ base: "10px", md: "100px", lg: "92px" }}
                >
                  <img src={name_preference} alt="Name tag" />
                </Box>
                OLUWATOSIN JESUTOFUNMI ERASTUS
              </Text>

              <Text mb="15px" textAlign={{ base: "justify", lg: "unset" }}>
                I am a product designer who is deliberate about implementing
                egalitarian, inclusive, and intuitive design solutions for
                people all around the world.
              </Text>

              <Text mb="15px" textAlign={{ base: "justify", lg: "unset" }}>
                My design journey is centered on performing design systems
                (reusable elements), UX audits, user research, and UI/UX designs
                to help provide solutions that satisfy the demands of consumers.
              </Text>

              <Text textAlign={{ base: "justify", lg: "unset" }}>
                Aside from work, I enjoy traveling, hanging out with friends,
                playing football, and listening to lo-fi music.
              </Text>
            </Box>

            <Flex
              wrap={{ base: "wrap", lg: "nowrap" }}
              columnGap="20px"
              w={{ base: "auto", lg: "550px" }}
              justify="space-between"
              px={{ base: "20px", lg: "0" }}
            >
              <Tooltip label="Click to view">
                <Button
                  variant="ghost"
                  w="91px"
                  h="95px"
                  p="0"
                  onClick={() => onViewImage("ibm")}
                  _active={{ bg: "transparent" }}
                  mb={{ base: "20px", md: "0" }}
                >
                  <img src={ibm} alt="certificate" />
                </Button>
              </Tooltip>

              <Tooltip label="Click to view">
                <Button
                  variant="ghost"
                  w="125px"
                  h="95px"
                  p="0"
                  _active={{ bg: "transparent" }}
                  onClick={() => onViewImage("utiva")}
                  mb={{ base: "20px", md: "0" }}
                >
                  <img src={utiva} alt="certificate" />
                </Button>
              </Tooltip>

              <Tooltip label="Click to view">
                <Button
                  variant="ghost"
                  w="125px"
                  h="100px"
                  p="0"
                  _active={{ bg: "transparent" }}
                  onClick={() => onViewImage("dSkills")}
                >
                  <img src={digital_skills} alt="certificate" />
                </Button>
              </Tooltip>

              <Tooltip label="Click to view">
                <Button
                  variant="ghost"
                  w="125px"
                  h="100px"
                  p="0"
                  _active={{ bg: "transparent" }}
                  onClick={() => onViewImage("google")}
                >
                  <img src={google} alt="certificate" />
                </Button>
              </Tooltip>
            </Flex>
          </Box>

          <Flex
            direction="column"
            alignSelf={{ base: "center", lg: "unset" }}
            bg={{ base: "transparent", lg: "#FFF3E7" }}
            w="217px"
            h={{ base: "auto", lg: "417px" }}
            pos="relative"
            zIndex="-1"
          >
            <Box
              w={{ base: "auto", lg: "220px", xl: "auto" }}
              border="1px solid #FFAA54"
              borderRadius="50%"
              pos="absolute"
              p="8px"
              left={{ lg: "-70px", xl: "-110px" }}
              bottom="-40px"
            >
              <img src={erastus} alt="Erastus" />
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        position="relative"
        overflow="hidden"
        mt="-40px"
        mb="100px"
        userSelect="none"
      >
        <Flex
          flexShrink="0"
          gap="1.5rem"
          justify="space-around"
          minW="100%"
          className="infinite-scroll"
        >
          {Array(4)
            .fill(0)
            .map((_, i) => (
              <Text key={i} fontSize="80px" mr="20px" color="#F2F2F2">
                I design good stuff.
              </Text>
            ))}
        </Flex>

        <Flex
          flexShrink="0"
          gap="1.5rem"
          justify="space-around"
          minW="100%"
          className="infinite-scroll"
        >
          {Array(4)
            .fill(0)
            .map((_, i) => (
              <Text key={i} fontSize="80px" mr="20px" color="#F2F2F2">
                I design good stuff.
              </Text>
            ))}
        </Flex>
      </Flex>

      <Box ref={companiesRef} mx={{ base: "30px", lg: "50px", xl: "180px" }}>
        <Companies />
      </Box>

      <Modal isOpen={show} onClose={() => setShow(false)} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <img src={imagePath()} alt="certificate" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
