import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";
import fundall_mobile from "assets/images/fundall-mobile-app-hero.png";
import features from "assets/images/fm-features.png";
import product from "assets/images/fundall-product.png";
import design_system from "assets/images/fm-design-system-bg.png";
import { ProductEvaluation } from "components/FundallMobile/product-evaluation";
import { ReviewSection } from "components/FundallMobile/review-section";

export const FundallMobileApp = () => {
  return (
    <>
      <Flex
        as="section"
        direction="column"
        mx={{ base: "10px", md: "50px", xl: "180px" }}
        pt="50px"
      >
        <Flex
          as="section"
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          bg={{
            base: "linear-gradient(359.44deg, #219653 -4.01%, rgba(255, 255, 255, 0) 123.48%)",
            lg: "linear-gradient(358.66deg, rgba(33, 150, 83, 0.46) -47.78%, rgba(255, 255, 255, 0) 98.38%)",
          }}
          h="40vh"
          pos="relative"
        >
          <Flex
            direction="column"
            h="100%"
            justify="end"
            pl={{ base: "10px", lg: "40px" }}
            pb="40px"
          >
            <Box fontSize="48px">
              <Box as="span" fontWeight="500">
                Fundall{" "}
              </Box>
              Mobile App
            </Box>
            <Text w={{ base: "auto", lg: "450px" }}>
              The Fundall company is a subsidiary of Emerging Africa Group. The
              company was acquired by the group in 2021
            </Text>
          </Flex>

          <Box
            w="100%"
            h="100%"
            pos={{ base: "absolute", lg: "unset" }}
            right="0"
            zIndex="-1"
            bgImage={fundall_mobile}
            bgSize="cover"
            bgRepeat="no-repeat"
          />
        </Flex>

        <Flex
          mt="80px"
          mb="60px"
          pb="70px"
          borderBottom="1px solid #E4E4E4"
          justify={{ base: "space-around", lg: "unset" }}
        >
          <Flex direction="column" mr="35%">
            <Box as="span" fontWeight="700">
              MY ROLE
            </Box>
            <Box as="span">Product Designer</Box>
          </Flex>

          <Flex direction="column">
            <Box as="span" fontWeight="700">
              TOOLS
            </Box>
            <Box as="span">Figma</Box>
          </Flex>
        </Flex>

        <Flex
          direction={{ base: "column", lg: "row" }}
          align={{ base: "center", lg: "unset" }}
          justify="space-between"
          mb="100px"
        >
          <Flex
            w={{ base: "100%", lg: "45%" }}
            direction="column"
            align={{ base: "center", lg: "unset" }}
            mb={{ base: "100px", lg: "unset" }}
          >
            <Box
              w="fit-content"
              as="span"
              fontWeight="500"
              color="#DBB736"
              bg="rgba(255, 229, 0, 0.08)"
              py="5px"
              px="10px"
            >
              OVERVIEW
            </Box>

            <Box mt="20px">
              <Text mb="20px" textAlign={{ base: "justify", lg: "unset" }}>
                Fundall (a subsidiary of Emerging Africa Group) is a financial
                technology business that focuses on providing{" "}
                <span style={{ fontWeight: "600" }}>“ALL THINGS MONEY”</span> to
                its subscribers. When the firm was bought, the product was
                anticipated to be aligned with the group's brand. While this was
                being done, the desire to improve the product's experience was a
                big priority because it helped boost our metrics.
              </Text>

              <Text mb="20px" textAlign={{ base: "justify", lg: "unset" }}>
                The firm provides a super app with numerous items divided into
                four categories, which include:
              </Text>

              <Text fontWeight="700">SPEND, BORROW, GROW & MANAGE</Text>
            </Box>
          </Flex>

          <Flex
            w={{ base: "100%", lg: "45%" }}
            direction="column"
            align={{ base: "center", lg: "unset" }}
            mb={{ base: "50px", lg: "unset" }}
          >
            <Box
              as="span"
              w="fit-content"
              fontWeight="500"
              color="#DBB736"
              bg="rgba(255, 229, 0, 0.08)"
              py="5px"
              px="10px"
            >
              TEAM MEMBERS
            </Box>

            <Flex
              direction="column"
              align={{ base: "center", lg: "unset" }}
              mt="20px"
            >
              <Flex>
                <Box as="span" fontWeight="600">
                  Obasan Taiwo
                </Box>
                - Head of Products{" "}
              </Flex>

              <Flex>
                <Box as="span" fontWeight="600">
                  Erastus Oluwatosin
                </Box>
                - Product Designer
              </Flex>

              <Flex>
                <Box as="span" fontWeight="600">
                  Excellent
                </Box>
                - Product Designer
              </Flex>

              <Flex>
                <Box as="span" fontWeight="600">
                  Chioma
                </Box>
                - Product Manager
              </Flex>

              <Flex>
                <Box as="span" fontWeight="600">
                  Abdulkabir
                </Box>
                - IOS Developer
              </Flex>

              <Flex>
                <Box as="span" fontWeight="600">
                  Femi
                </Box>
                - Andriod developer
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          mb="100px"
        >
          <Flex
            w={{ base: "100%", lg: "45%" }}
            direction="column"
            align={{ base: "center", lg: "unset" }}
            mb={{ base: "100px", lg: "unset" }}
          >
            <Box
              as="span"
              w="fit-content"
              fontWeight="500"
              color="#DBB736"
              bg="rgba(255, 229, 0, 0.08)"
              py="5px"
              px="10px"
            >
              ALL FEATURES
            </Box>

            <Flex mt="20px">
              <img src={features} alt="App Features" />
            </Flex>
          </Flex>

          <Flex
            w={{ base: "100%", lg: "45%" }}
            direction="column"
            align={{ base: "center", lg: "unset" }}
          >
            <Box
              as="span"
              w="fit-content"
              fontWeight="500"
              color="#DBB736"
              bg="rgba(255, 229, 0, 0.08)"
              py="5px"
              px="10px"
            >
              WORKING WITH TEAM
            </Box>

            <Box mt="20px">
              <Text>
                While working with the team, I was in charge of the following:
              </Text>
              <Text fontWeight="600">
                1. Evaluating the experience of the product and improving the
                experience using key UX principles
              </Text>

              <Text>Key sections I worked on include:</Text>
              <Box as="ul" pl="30px">
                <Box as="li">
                  GROW (Mutual funds, Fixed savings & Insurance)
                </Box>
                <Box as="li">ONBOARDING</Box>
                <Box as="li">CARDS</Box>
                <Box as="li">TRANSFERS</Box>
                <Box as="li">SAVE AS YOU SPEND (SAYS)</Box>
                <Box as="li">ACCOUNT ACTIVATION</Box>
              </Box>

              <Text fontWeight="600">
                2. Developing a design system to help reduce consistency error
                and increase quality.
              </Text>

              <Link
                href="https://animixplay.to/v1/fuuto-tantei/ep2"
                color="#009FD9"
                target="_blank"
                textDecor="underline"
              >
                You can find the documentation here
              </Link>
            </Box>
          </Flex>
        </Flex>

        <Box as="section">
          <Flex
            justify="space-between"
            mb="100px"
            direction={{ base: "column", lg: "row" }}
            align={{ base: "center", lg: "unset" }}
            pos="relative"
          >
            <Flex
              direction="column"
              justify={{ base: "space-between", lg: "unset" }}
              w={{ base: "100%", lg: "45%" }}
              pt={{ base: "0", lg: "70px" }}
              h={{ base: "430px", md: "510px", lg: "auto" }}
            >
              <Box
                as="span"
                w="fit-content"
                fontWeight="500"
                color="#DBB736"
                bg="rgba(255, 229, 0, 0.08)"
                py="5px"
                px="10px"
                mx={{ base: "auto", lg: "unset" }}
              >
                DEVELOPING THE PRODUCT
              </Box>

              <Text mt="20px" textAlign="justify">
                Sketching out user journeys, brainstorming concepts, and
                building information architecture was all part of the product
                development process. These actions aided us in developing an
                intuitive and all-inclusive solution for the product.
              </Text>
            </Flex>

            <Flex
              w={{ base: "auto", lg: "50%", xl: "auto" }}
              pos={{ base: "absolute", lg: "unset" }}
              zIndex="-1"
              top={{ base: "60px", lg: "unset" }}
            >
              <img src={product} alt="Product" />
            </Flex>
          </Flex>
        </Box>

        <ProductEvaluation />

        <Box
          as="section"
          borderRadius="10px"
          bg="#21965314"
          p="25px"
          h={{ base: "640px", md: "600px", lg: "400px" }}
          mb="150px"
        >
          <Flex
            direction={{ base: "column-reverse", lg: "row" }}
            bg="#2196532E"
            borderRadius="10px"
            pos="relative"
            h="100%"
            align="center"
          >
            <Flex
              pl={{ base: "0", lg: "30px" }}
              pb={{ base: "35px", lg: "0" }}
              w={{ base: "90%", lg: "360px" }}
              direction="column"
            >
              <Box
                as="span"
                fontWeight="700"
                color="#008751"
                mb="20px"
                fontSize={{ base: "40px", lg: "48px" }}
              >
                Design Systems
              </Box>

              <Text mb="20px">
                I developed a design system that helped developers and designer
                improve the quality of the product. You view the system
                documentation by clicking the button below
              </Text>

              <Button
                variant="main"
                w="180px"
                h="50px"
                className="skewed-button"
              >
                <Link
                  href="https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:869f579a-c3c1-3793-b7e1-8d0a978cdf88"
                  target="_blank"
                  _hover={{ textDecor: "none" }}
                  className="straight-text"
                >
                  View Documentation
                </Link>
              </Button>
            </Flex>

            <Flex
              pos="absolute"
              right={{ base: "unset", lg: "0" }}
              top={{ base: "0", lg: "unset" }}
              h={{ base: "230px", lg: "auto" }}
              maxH="100%"
            >
              <Image
                borderRadius={{ base: "10px", lg: "0" }}
                w={{ md: "600px", lg: "auto" }}
                h={{ md: "290px", lg: "auto" }}
                src={design_system}
                alt="Design System"
              />
            </Flex>
          </Flex>
        </Box>

        <Box as="section" mb="120px">
          <Flex
            as="span"
            w="fit-content"
            mx={{ base: "auto", lg: "unset" }}
            fontWeight="500"
            color="#DBB736"
            bg="rgba(255, 229, 0, 0.08)"
            py="5px"
            px="10px"
          >
            IMPACT OF THE PRODUCT
          </Flex>

          <Flex mt="20px" direction={{ base: "column-reverse", lg: "row" }}>
            <Box
              w={{ base: "100%", lg: "560px" }}
              px={{ base: "12px", lg: "0" }}
            >
              <Text mb="20px">
                The fintech industry in Nigeria is a highly saturated sector
                with lots of competition. However, there are still available
                opportunities that can be accessed. In order to improve access
                to hitch free payments, Fundall has tapped into these
                opportunities that have helped create positive numbers for the
                Fundall mobile app.
              </Text>
              <Text>
                The numbers stated here is the level impact the Fundall mobile
                app has made in the fintech industry between Feburary, 2022 and
                November, 2022. I am glad to be part of this project
              </Text>
            </Box>

            <Flex
              direction="column"
              flexGrow="1"
              mb={{ base: "50px", lg: "0" }}
            >
              <Flex justify="space-around">
                <Flex
                  borderRadius="50%"
                  bg="white"
                  w="272px"
                  h="272px"
                  direction="column"
                  align="center"
                  boxShadow=" 0px 10px 40px rgba(0, 0, 0, 0.05)"
                  mb="10px"
                  alignSelf="center"
                  ml={{ base: "0", lg: "140px" }}
                >
                  <Box as="span" fontWeight="700" fontSize="64px" my="20px">
                    50.23%
                  </Box>
                  <Text w="178px" textAlign="center">
                    Transaction value increased by 50.23% between Feburary and
                    November, 2022
                  </Text>
                </Flex>

                <Flex
                  display={{ base: "none", lg: "flex" }}
                  borderRadius="50%"
                  bg="white"
                  mt="-60px"
                  w={{ base: "225px", lg: "230px" }}
                  h={{ base: "225px", lg: "230px" }}
                  direction="column"
                  align="center"
                  boxShadow=" 0px 10px 40px rgba(0, 0, 0, 0.05)"
                >
                  <Box as="span" fontWeight="700" fontSize="64px" mt="20px">
                    22.5%
                  </Box>
                  <Text w="148px" textAlign="center">
                    Total number of active users grew by 22.5% between Feburary
                    and November, 2022
                  </Text>
                </Flex>
              </Flex>

              <Flex justify="space-between">
                <Flex
                  borderRadius="50%"
                  bg="white"
                  w={{ base: "200px", lg: "250px" }}
                  h={{ base: "225px", lg: "250px" }}
                  direction="column"
                  align="center"
                  boxShadow=" 0px 10px 40px rgba(0, 0, 0, 0.05)"
                >
                  <Box as="span" fontWeight="700" fontSize="64px" mt="20px">
                    24%
                  </Box>
                  <Text w="148px" textAlign="center">
                    Total number of users grew by 24% between Feburary and
                    November, 2022
                  </Text>
                </Flex>

                <Flex
                  borderRadius="50%"
                  bg="white"
                  w={{ base: "198px", lg: "250px" }}
                  h={{ base: "215px", lg: "250px" }}
                  direction="column"
                  align="center"
                  boxShadow=" 0px 10px 40px rgba(0, 0, 0, 0.05)"
                >
                  <Box as="span" fontWeight="700" fontSize="64px">
                    48%
                  </Box>
                  <Text w="148px" textAlign="center">
                    Total number of inactive users decreased by 48% between
                    Feburary and November, 2022
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>

      <ReviewSection />
    </>
  );
};
