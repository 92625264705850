import React, { createContext, useState } from "react";
interface MouseProviderProps {
  children: React.ReactNode;
}

export const MouseContext = createContext({
  cursorType: "",
  cursorChangeHandler: (_cursorType: string) => {},
});

const MouseContextProvider = ({ children }: MouseProviderProps) => {
  const [cursorType, setCursorType] = useState("");

  const cursorChangeHandler = (cursorType: string) => {
    setCursorType(cursorType);
  };

  return (
    <MouseContext.Provider
      value={{
        cursorType: cursorType,
        cursorChangeHandler: cursorChangeHandler,
      }}
    >
      {children}
    </MouseContext.Provider>
  );
};

export default MouseContextProvider;
