import { Box, Flex, Text } from "@chakra-ui/react";
import {
  BottomRowReviews,
  TopRowReviews,
} from "components/FundallMobile/reviews";

export const ReviewSection = () => {
  return (
    <Flex direction="column" mb="120px">
      <Flex
        as="span"
        w="fit-content"
        alignSelf={{ base: "center", lg: "unset" }}
        fontWeight="500"
        color="#DBB736"
        bg="rgba(255, 229, 0, 0.08)"
        py="5px"
        px="10px"
        ml={{ base: "0", lg: "180px" }}
      >
        WHAT USERS ARE SAYING ABOUT THE PRODUCT
      </Flex>

      <Flex
        position="relative"
        overflow="hidden"
        mt="20px"
        mb="30px"
        userSelect="none"
      >
        <Flex
          flexShrink="0"
          gap="4.5rem"
          justify="space-around"
          minW="100%"
          mr="65px"
          className="infinite-review-scroll"
        >
          {TopRowReviews.map(({ name, review }, i) => (
            <Flex
              key={i}
              pos="relative"
              h="160px"
              direction="column"
              justify="end"
            >
              <Box bg="#21965314" borderRadius="10px" w="545px" h="120px"></Box>
              <Flex
                bottom="12px"
                left="12px"
                fontWeight="700"
                p="20px"
                bg="white"
                borderRadius="10px"
                pos="absolute"
                direction="column"
                justify="space-between"
                h="120px"
                w="550px"
                border="1px solid #D9D9D9"
              >
                <Text>{review}</Text>
                <Box>—{name}</Box>
              </Flex>
            </Flex>
          ))}
        </Flex>

        <Flex
          flexShrink="0"
          gap="4.5rem"
          justify="space-around"
          minW="100%"
          className="infinite-review-scroll"
        >
          {TopRowReviews.map(({ name, review }, i) => (
            <Flex
              key={i}
              pos="relative"
              h="160px"
              direction="column"
              justify="end"
            >
              <Box bg="#21965314" borderRadius="10px" w="545px" h="120px"></Box>
              <Flex
                bottom="12px"
                left="12px"
                fontWeight="700"
                p="20px"
                bg="white"
                borderRadius="10px"
                pos="absolute"
                direction="column"
                justify="space-between"
                h="120px"
                w="550px"
                border="1px solid #D9D9D9"
              >
                <Text>{review}</Text>
                <Box>—{name}</Box>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>

      <Flex
        position="relative"
        overflow="hidden"
        mt="20px"
        mb="100px"
        userSelect="none"
      >
        <Flex
          flexShrink="0"
          gap="4.5rem"
          justify="space-around"
          minW="100%"
          className="infinite-counter-review-scroll"
        >
          {BottomRowReviews.map(({ name, review }, i) => (
            <Flex
              key={i}
              pos="relative"
              h="160px"
              direction="column"
              justify="end"
            >
              <Box bg="#21965314" borderRadius="10px" w="545px" h="120px"></Box>
              <Flex
                bottom="12px"
                left="12px"
                fontWeight="700"
                p="20px"
                bg="white"
                borderRadius="10px"
                pos="absolute"
                direction="column"
                justify="space-between"
                h="120px"
                w="550px"
                border="1px solid #D9D9D9"
              >
                <Text>{review}</Text>
                <Box>—{name}</Box>
              </Flex>
            </Flex>
          ))}
        </Flex>

        <Flex
          flexShrink="0"
          gap="4.5rem"
          justify="space-around"
          minW="100%"
          ml="65px"
          className="infinite-counter-review-scroll"
        >
          {BottomRowReviews.map(({ name, review }, i) => (
            <Flex
              key={i}
              pos="relative"
              h="160px"
              direction="column"
              justify="end"
            >
              <Box bg="#21965314" borderRadius="10px" w="545px" h="120px"></Box>
              <Flex
                bottom="12px"
                left="12px"
                fontWeight="700"
                p="20px"
                bg="white"
                borderRadius="10px"
                pos="absolute"
                direction="column"
                justify="space-between"
                h="120px"
                w="550px"
                border="1px solid #D9D9D9"
              >
                <Text>{review}</Text>
                <Box>—{name}</Box>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
