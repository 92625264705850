import { Box, Flex } from "@chakra-ui/react";
import { Link, Outlet, useLocation } from "react-router-dom";

export const Projects = () => {
  const { pathname } = useLocation();

  return (
    <Flex
      as="section"
      direction="column"
      pt="5px"
      mx={{ base: "20px", lg: "50px", xl: "180px" }}
      mb="130px"
    >
      <Flex
        py="20px"
        px={{ base: "0", lg: "40px" }}
        borderBottom="1px solid #D9D9D9"
        mb="50px"
      >
        <Link to="fundall">
          <Box
            as="span"
            px="20px"
            pb="20px"
            mr="30px"
            fontWeight="500"
            borderBottom={
              pathname === "/projects/fundall" || pathname === "/projects"
                ? "2px solid black"
                : "none"
            }
          >
            Fundall
          </Box>
        </Link>

        <Link to="fbn">
          <Box
            as="span"
            px="20px"
            pb="20px"
            fontWeight="500"
            borderBottom={pathname.includes("fbn") ? "2px solid black" : "none"}
          >
            FirstBankNigeria
          </Box>
        </Link>
      </Flex>

      <Outlet />
    </Flex>
  );
};
