import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import location from "assets/icons/location.svg";
import uk from "assets/icons/uk.svg";
import scroll from "assets/icons/scroll-down.svg";
import { CaseStudies } from "components/CaseStudies";
import { Recommendations } from "components/Recommendations";
import { useRef } from "react";

export const Home = () => {
  const navigate = useNavigate();
  const projectsRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Flex
        as="section"
        direction="column"
        align="center"
        pt={{ base: "50px", lg: "50px" }}
      >
        <Flex
          mb="30px"
          align="center"
          direction={{ base: "column-reverse", md: "row" }}
        >
          <Flex mr={{ base: "0", md: "30px" }}>
            <Text fontWeight="600" mr="5px">
              Product Designer
            </Text>
            <Text>(ọja oniru)</Text>
          </Flex>

          <Flex
            bg="rgba(200, 16, 46, 0.08)"
            align="center"
            py="5px"
            px="15px"
            mb={{ base: "10px", md: "0" }}
          >
            <img src={location} alt="location" />
            <Text color="#EB5757" mx="5px">
              United Kingdom
            </Text>
            <Box h="11px">
              <img src={uk} alt="UNited Kingdom" />
            </Box>
          </Flex>
        </Flex>

        <Text
          fontSize={{ base: "30px", md: "48px" }}
          w="75%"
          textAlign="center"
          mb="80px"
        >
          <span style={{ fontWeight: "500" }}>Erastus </span>
          loves to create <span style={{ fontWeight: "500" }}> equitable </span>
          and
          <span style={{ fontWeight: "500" }}> intuitive </span>
          design solutions for
          <span style={{ fontWeight: "500" }}> people </span>
          across borders
        </Text>

        <Flex mb="20px" direction={{ base: "column-reverse", md: "row" }}>
          <Button
            variant="clear"
            w="180px"
            h="50px"
            mr={{ base: "0", md: "30px" }}
            className="skewed-button"
            onClick={() => navigate("/about")}
          >
            <Box as="span" className="straight-text">
              About Erastus
            </Box>
          </Button>
          <Button
            variant="main"
            w="180px"
            h="50px"
            mb={{ base: "30px", md: "0" }}
            className="skewed-button"
            onClick={() => projectsRef.current?.scrollIntoView()}
          >
            <Box as="span" className="straight-text">
              Top Projects
            </Box>
          </Button>
        </Flex>

        <Flex direction="column" mt={{ lg: "4%", xl: "5%" }} align="center">
          <Box className="levitating" mb="20px">
            <img src={scroll} alt="Scroll" />
          </Box>
          <Text>scroll down</Text>
        </Flex>

        <Flex
          position="relative"
          overflow="hidden"
          mb="100px"
          userSelect="none"
        >
          <Flex
            flexShrink="0"
            gap="1.5rem"
            justify="space-around"
            minW="100%"
            className="infinite-scroll"
          >
            {Array(4)
              .fill(0)
              .map((_, i) => (
                <Text key={i} fontSize="80px" mr="20px" color="#F2F2F2">
                  I design good stuff.
                </Text>
              ))}
          </Flex>

          <Flex
            flexShrink="0"
            gap="1.5rem"
            justify="space-around"
            minW="100%"
            className="infinite-scroll"
          >
            {Array(4)
              .fill(0)
              .map((_, i) => (
                <Text key={i} fontSize="80px" mr="20px" color="#F2F2F2">
                  I design good stuff.
                </Text>
              ))}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        as="section"
        direction={{ base: "column", lg: "row" }}
        borderBottom="1px solid #E4E4E4"
        mx={{ base: "30px", lg: "50px", xl: "180px" }}
        justify="space-between"
        pb="70px"
        mb="50px"
      >
        <Text
          w={{ base: "auto", lg: "30%", xl: "382px" }}
          fontSize="18px"
          mb={{ base: "50px", lg: "0" }}
        >
          I currently work with a top bank in Lagos (the city of business in
          Nigeria).
        </Text>
        <Text
          w={{ base: "auto", lg: "65%", xl: "732px" }}
          fontSize="18px"
          textAlign="justify"
        >
          As a designer, working on projects that aligns organisations goals and
          objectives with it’s users need has been my core focus. I have worked
          on several products that cuts across industries while working within
          small and large teams.
        </Text>
      </Flex>

      <Box ref={projectsRef}>
        <CaseStudies />
      </Box>

      <Recommendations />
    </>
  );
};
