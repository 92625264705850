import fundall from "assets/images/fundall_avi.png";
import fbn from "assets/images/fbn_avi.png";

interface Companies {
  name: string;
  description: string;
  image: string;
  role: string;
  year: string;
  fontColor: string;
  site_Link: string;
  site_URL: string;
  projects_link: string;
}

export const companies: Companies[] = [
  {
    name: "FirstBankNigeria",
    description:
      "Working at FirstBankNigeria involved conducting user research & conceptualizing design solutions that serves the company’s objectives and it’s users.",
    role: "Product Designer",
    year: "2022",
    image: fbn,
    fontColor: "#033C7D",
    site_Link: "firstbanknigeria.com",
    site_URL: "https://www.firstbanknigeria.com/",
    projects_link: "/projects/fbn",
  },
  {
    name: "Fundall",
    description:
      "I worked with Fundall (a subsidiary fintech company of Emerging Africa) as a product designer where I designed solutions that launched the firm into digitizing their services.",
    role: "Product Designer",
    year: "2022",
    image: fundall,
    fontColor: "#FFAA54",
    site_Link: "fundall.io",
    site_URL: "https://fundall.io/",
    projects_link: "/projects/fundall",
  },
];
