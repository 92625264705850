import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import homepage from "assets/images/fm-homepage.png";
import homepage_iphone from "assets/images/fm-iphone-homepage.png";
import transfer_page from "assets/images/fm-transfer.png";
import transfer_iphone from "assets/images/fm-iphone-transfer.png";
import wealth_page from "assets/images/fm-wealth.png";
import wealth_iphone from "assets/images/fm-iphone-wealth.png";
import cards_page from "assets/images/fm-cards.png";
import cards_iphone from "assets/images/fm-iphone-cards.png";
import activation from "assets/images/fm-account-activation.png";

export const ProductEvaluation = () => {
  const [show, setShow] = useState(false);
  const [source, setSource] = useState("");

  const handleVideo = (src: string) => {
    setSource(src);
    setShow(true);
  };

  return (
    <Box as="section">
      <Flex
        mb={{ base: "170%", md: "70%", lg: "200px", xl: "120px" }}
        pos="relative"
      >
        <Flex justify="space-between" pt={{ base: "50px", xl: "0" }}>
          <Flex
            w={{ base: "55%", lg: "76%", xl: "auto" }}
            pt={{ lg: "20px", xl: "0" }}
          >
            <img src={homepage} alt="Home screen" />
          </Flex>
          <Flex
            w={{ base: "40%", md: "25%", lg: "30%", xl: "auto" }}
            pos="absolute"
            zIndex="2"
            right={{ base: "0", lg: "-20px" }}
            pt="30px"
            justify="end"
          >
            <img src={homepage_iphone} alt="homepage" />
          </Flex>
        </Flex>

        <Flex
          direction="column"
          pos="absolute"
          right={{ base: "auto", xl: "60px" }}
          w={{ base: "100%", xl: "60%" }}
          align={{ base: "center", xl: "normal" }}
          pt={{ base: "0", xl: "80px" }}
          pl={{ base: "0", xl: "30px" }}
          mb="30px"
        >
          <Box
            as="span"
            fontWeight="700"
            fontSize="32px"
            pos="relative"
            top={{ base: "0px", xl: "auto" }}
          >
            Revamping the Homepage
          </Box>

          <Box
            w={{ base: "auto", lg: "40%", xl: "auto" }}
            mt={{ base: "360px", lg: "80px", xl: "0" }}
          >
            <Box
              mb="30px"
              w={{ base: "100%", xl: "60%" }}
              px={{ base: "12px", lg: "0" }}
            >
              <Text mb="20px">
                While revamping the homepage, the team conducted a test to
                identify how users{" "}
                <Text as="span" fontWeight="600">
                  interact with the screen, the most important actions users
                  take, and what is irrelevant.
                </Text>{" "}
              </Text>

              <Box fontWeight="600">
                After the test, we realised the following:
              </Box>

              <Box as="ol" pl="20px">
                <Box as="li">
                  The search field was uneccessary because users hardly use the
                  search field to carry out an action.
                </Box>
                <Box as="li">
                  The “Edit Action” button was a wrong use of UX as it gave
                  limitations to accessing other valuable feature
                </Box>
                <Box as="li">
                  The community button was an unnecessary button. We needed to
                  create larger buttons that helped users switch between their
                  Chest (Wallet) and their Cards
                </Box>
              </Box>
            </Box>

            <Box bg="#FFAA541A" p="40px">
              <Text fontWeight="600" w={{ base: "100%", lg: "335px" }}>
                The new findings were the basis of the redesign that helped
                users interact better with the product
              </Text>
            </Box>
          </Box>
        </Flex>
      </Flex>

      <Flex
        mb={{ base: "200%", md: "85%", lg: "300px", xl: "250px" }}
        pos="relative"
      >
        <Flex
          pt={{ base: "50px", lg: "20px", xl: "0" }}
          justify="space-between"
        >
          <Flex
            w={{ base: "55%", lg: "80%", xl: "auto" }}
            pt={{ lg: "60px", xl: "40px" }}
          >
            <img src={transfer_page} alt="Transfer screen" />
          </Flex>
          <Flex
            w={{ base: "40%", md: "25%", lg: "25%", xl: "auto" }}
            pos="absolute"
            zIndex="2"
            right="0"
            pt={{ base: "30px", lg: "90px" }}
            justify="end"
          >
            <img src={transfer_iphone} alt="Transfer screen" />
          </Flex>
        </Flex>

        <Flex
          direction="column"
          pos="absolute"
          right={{ base: "auto", xl: "60px" }}
          w={{ base: "100%", xl: "60%" }}
          align={{ base: "center", xl: "normal" }}
          pt={{ base: "0", xl: "80px" }}
          pl={{ base: "0", xl: "30px" }}
          mb="30px"
        >
          <Box
            as="span"
            fontWeight="700"
            fontSize="32px"
            pos="relative"
            top={{ base: "0px", xl: "auto" }}
          >
            Transfer Funds
          </Box>

          <Box
            w={{ lg: "40%", xl: "auto" }}
            mt={{ base: "360px", lg: "80px", xl: "0" }}
            ml={{ base: "0", lg: "45px", xl: "0" }}
          >
            <Box
              mb="30px"
              w={{ base: "100%", xl: "60%" }}
              px={{ base: "12px", lg: "0" }}
            >
              <Text mb="20px">
                Transferring cash is a major activity carried out by over 85% of
                active users.
              </Text>

              <Text mb="20px">
                While trying to improve this interface, I conducted a test with
                users where monitored how users interacted with the app. The
                task was for users to complete a transaction flow. Key metrics
                used here were the time to completion and successful completion
                task rate.
              </Text>

              <Text mb="20px">
                Three of the five users were able to complete the transfer job
                in 2:15 minutes, while the other two were unable to traverse the
                flow.
              </Text>

              <Text>
                I went ahead to access the user pain points, One major pain
                point that was common among these users was; “At first glance,
                they were a bit confused as to how they would carry out the
                transfer task”.
              </Text>
            </Box>

            <Box bg="#FFAA541A" p="20px">
              <Text w="345px" fontWeight="600" mb="10px">
                The feedback from users helped create more clarity on how we can
                improve the UX by guiding the user through every action they
                take
              </Text>
              <Button
                variant="ghost"
                textDecor="underline"
                color="#2D9CDB"
                p="0"
                onClick={() =>
                  handleVideo(
                    "https://res.cloudinary.com/du0ibmdsx/video/upload/v1670603421/Transfer_Money_kbtjde.mov"
                  )
                }
              >
                Click here to view the new transfer flow
              </Button>
            </Box>
          </Box>
        </Flex>
      </Flex>

      <Flex
        mb={{ base: "180%", md: "80%", lg: "200px", xl: "120px" }}
        pos="relative"
      >
        <Flex
          pt={{ base: "80px", lg: "20px", xl: "0" }}
          justify="space-between"
        >
          <Flex
            w={{ base: "55%", lg: "80%", xl: "auto" }}
            pt={{ lg: "60px", xl: "0" }}
          >
            <img src={wealth_page} alt="Wealth screen" />
          </Flex>

          <Flex
            w={{ base: "45%", md: "28%", lg: "25%" }}
            pos="absolute"
            zIndex="2"
            right={{ base: "-5px", md: "0" }}
            pt={{ lg: "65px", xl: "0" }}
            justify="end"
          >
            <img src={wealth_iphone} alt="homepage" />
          </Flex>
        </Flex>

        <Flex
          direction="column"
          pos="absolute"
          right={{ base: "auto", xl: "60px" }}
          w={{ base: "100%", xl: "60%" }}
          align={{ base: "center", xl: "normal" }}
          pl={{ base: "0", xl: "30px" }}
          mb="30px"
        >
          <Box
            as="span"
            fontWeight="700"
            fontSize="32px"
            pos="relative"
            top={{ base: "0px", lg: "auto" }}
          >
            Wealth
          </Box>

          <Box
            w={{ lg: "40%", xl: "60%" }}
            mt={{ base: "390px", lg: "50px", xl: "0" }}
            mb="30px"
            ml={{ lg: "30px", xl: "0" }}
            px={{ base: "12px", lg: "0" }}
          >
            <Text mb="20px">
              The Wealth section of the Fundall app is an integral part that
              focuses on helping users grow their money. What happens here is
              users get to earn interests on their savings.
            </Text>

            <Text mb="20px">
              Previous feature was only Fixed savings. After the acquisition,
              more features like mutual funds and insurance were added to help
              users have a flexible and broader investment portfolio.
            </Text>

            <Text mb="20px">
              These forced a realignment of the existing information
              architecture. Initially the design catered for just one category.
              Now, there was a need to make the design cater for three
              categories.
            </Text>

            <Text>
              This was quite taxing because it involved multiple iterations.
            </Text>
          </Box>

          <Box bg="#FFAA541A" p="20px" w={{ lg: "40%", xl: "auto" }}>
            <Text w={{ lg: "auto", xl: "345px" }} fontWeight="600" mb="10px">
              The new design caters for all 3 categories of Wealth which include
              Fixed savings, Mutual funds & Insurance
            </Text>
            <Button
              w="fit-content"
              variant="ghost"
              textDecor="underline"
              color="#2D9CDB"
              p="0"
              onClick={() =>
                handleVideo(
                  "https://res.cloudinary.com/du0ibmdsx/video/upload/v1670603467/Growing_money_jo9yqu.mov"
                )
              }
            >
              Click here to view the new Wealth flow
            </Button>
          </Box>
        </Flex>
      </Flex>

      <Flex
        mb={{ base: "260%", md: "130%", lg: "40%", xl: "300px" }}
        pos="relative"
      >
        <Flex
          pt={{ base: "40px", lg: "50px", xl: "0" }}
          justify="space-between"
        >
          <Flex
            w={{ base: "55%", md: "65%", lg: "80%", xl: "auto" }}
            h="fit-content"
            pt="60px"
          >
            <img src={cards_page} alt="Transfer screen" />
          </Flex>
          <Flex
            pos="absolute"
            w={{ base: "55%", md: "40%", lg: "340px", xl: "450px" }}
            zIndex="2"
            right={{ base: "0", lg: "-60px", xl: "-100px" }}
            pt="60px"
          >
            <img src={cards_iphone} alt="homepage" />
          </Flex>
        </Flex>

        <Flex
          direction="column"
          pos="absolute"
          right={{ base: "auto", xl: "100px" }}
          w={{ base: "100%", xl: "60%" }}
          align={{ base: "center", xl: "normal" }}
          pt={{ base: "0", xl: "80px" }}
          pl={{ base: "0", xl: "30px" }}
          mb="30px"
        >
          <Box
            as="span"
            fontWeight="700"
            fontSize="32px"
            pos="relative"
            top={{ base: "0px", lg: "auto" }}
          >
            Cards
          </Box>

          <Box
            w={{ lg: "40%", xl: "62%" }}
            mt={{ base: "400px", md: "70%", lg: "70px", xl: "0" }}
            mb="30px"
            px={{ base: "12px", lg: "0" }}
          >
            <Text mb="20px">
              The card section is very essential because Fundall receives an
              average of 5 card request per week. Currently, we have 2 cards
              which include:
            </Text>

            <Box as="ol" mb="20px" pl="20px">
              <Box as="li">Lifestyle Naira card (Physical card)</Box>
              <Box as="li">USD cards (Virtual card)</Box>
            </Box>

            <Text mb="20px">
              Currently, users have access to only one physical and virtual card
              which our they enjoy. However, we noticed users began to request
              for extra cards for their loved ones. <br />
              <Text as="span" fontWeight="600">
                We had a user who wanted extra cards for her children so she can
                fund their cards monthly and her kids can use their cards to
                carry out expenses.
              </Text>
            </Text>

            <Text>
              This created the need for us to rethink the interface of the cards
              screen. A brainstorming was conducted where we explored possible
              ways the cards screen can be represented.
            </Text>

            <Box>
              <Text fontWeight="600">
                We eventually arrived at a solution where users get to carry out
                all possible actions at a glance. With the new screens, users
                can ;
              </Text>
              <Box as="ol" fontWeight="600" pl="20px">
                <Box as="li">Request a new card</Box>
                <Box as="li">Add a physical card</Box>
                <Box as="li">Switch between card categories</Box>
                <Box as="li">View all cards under a card category</Box>
              </Box>
            </Box>
          </Box>

          <Box
            bg="#FFAA541A"
            p="20px"
            w={{ base: "100%", lg: "40%", xl: "auto" }}
          >
            <Text w={{ lg: "auto", xl: "345px" }} fontWeight="600" mb="10px">
              Currently, users can request for mulitple cards with ease
            </Text>
            <Button
              variant="ghost"
              textDecor="underline"
              color="#2D9CDB"
              p="0"
              onClick={() =>
                handleVideo(
                  "https://res.cloudinary.com/du0ibmdsx/video/upload/v1670603486/Cards_td3hk7.mov"
                )
              }
            >
              Click here to view the new Cards flow
            </Button>
          </Box>
        </Flex>
      </Flex>

      <Flex
        direction={{ base: "column-reverse", lg: "row" }}
        mb="120px"
        justify="space-between"
      >
        <Flex
          direction="column"
          w={{ base: "100%", lg: "45%" }}
          textAlign="justify"
        >
          <Box
            as="span"
            pos="relative"
            top={{ base: "-430px", md: "-700px", lg: "unset" }}
            fontWeight="700"
            fontSize="32px"
            textAlign={{ base: "center", lg: "unset" }}
          >
            Account Activation
          </Box>
          <Text mb="15px">
            When onboarding into the Fundall app, users are expected to access
            their account number which is a necessity when creating an account
            on the app.
          </Text>

          <Text mb="15px">
            When generating an account number, the Bank Verification Number
            (BVN) will be required. The BVN is a 10 digit number that can give
            anyone access to your identity as a citizen of the country which
            makes the BVN a very critical item people do not like to release.
          </Text>

          <Text>
            In our case, we needed users to enter their BVN details in order to
            help us generate an account number for their account but feedbacks
            from our users include:
          </Text>
          <Box as="ol" mb="15px" pl="20px" fontWeight="600">
            <Box as="li">"I’m not sure if i can drop my BVN oooo"</Box>
            <Box as="li">
              "How can i trust your platform to keep my details"
            </Box>
          </Box>

          <Text my="20px">
            These thought from our users made them lose interest in onboarding.
          </Text>

          <Box>
            <Box as="span" fontWeight="700">
              How did we solve this issue?
            </Box>

            <Text mb="15px">
              We considered how users felt regarding the onboarding requirements
              and began a brainstorming session to think of the best way we can
              gain the trust of our users and still get the users to provide
              their BVN.
            </Text>

            <Box mb="15px">
              After ideating several ways, we eventually arrive at a solution.
            </Box>

            <Text>
              Our solution was to allow users to complete their onboarding
              without their BVN and once onboarding is completed, users can
              access the limited functionalities of the app. In order the access
              full functionality of the app, the user is required to activate
              their account.
            </Text>
          </Box>

          <Text mb="15px">
            When activating the account, users would be required to enter their
            BVN which would help us generate an account number.
          </Text>

          <Text mb="20px">
            With this solution, we were able to help users onboard fully and
            trust the app in a better way.
          </Text>

          <Button
            w="fit-content"
            variant="ghost"
            textDecor="underline"
            color="#2D9CDB"
            p="0"
            onClick={() =>
              handleVideo(
                "https://res.cloudinary.com/du0ibmdsx/video/upload/v1670606379/Account_Activation_w3x8zk.mov"
              )
            }
          >
            Click here to view the new account activation flow
          </Button>
        </Flex>

        <Flex
          w={{ base: "70%", lg: "45%" }}
          maxH={{ lg: "690px" }}
          mx={{ base: "auto", lg: "unset" }}
        >
          <img src={activation} alt="Account activation" />
        </Flex>
      </Flex>

      <Modal isOpen={show} onClose={() => setShow(false)} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalBody>
            <video
              controls
              width="100%"
              style={{ maxHeight: "470px", background: "black" }}
            >
              <source src={source} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
