import ea_trustees from "assets/images/ea_trustees.png";
import ea_wealth from "assets/images/emerging_wealth.png";
import ea_ventures from "assets/images/emerging_ventures.png";
import ea_advisory from "assets/images/emerging_advisory.png";
import fundall_mobile from "assets/images/fundall_mobile.png";
import potters_choice from "assets/images/potters_choice.png";
import tfs from "assets/images/tfs_foundation.png";

interface FundallProject {
  title: string;
  description: string;
  image: string;
  category: string;
  link: string;
}

export const FundallProjects: FundallProject[] = [
  {
    title: "EA Trustees",
    category: "web",
    description:
      "The Emerging Africa Trustees platform is a product for individuals who want to create will & trust documents to secure thier future.",
    image: ea_trustees,
    link: "https://eatrustees.emergingafricagroup.com/",
  },
  {
    title: "Emerging Wealth",
    category: "mobile",
    description:
      "The Emerging Wealth platform is an asset management platform that allows users carryout NGN and USD denominated investments",
    image: ea_wealth,
    link: "https://apps.apple.com/ng/app/emerging-wealth/id1635791469",
  },
  {
    title: "EA Ventures",
    category: "web",
    description:
      "The Emerging Africa Ventures platform is a product designed to create opportunities for startups to source for fundings. With this product, the Venture capital division of the Emerging Africa Group has expanded it’s investment in several startup companies.",
    image: ea_ventures,
    link: "https://eavc.emergingafricagroup.com/",
  },
  {
    title: "TFS Foundation",
    category: "web",
    description:
      "The Toyin F. Sanni Foundation platform was designed to help facilitate charitable activities and donations to vulnerable african women and children. This product was successfully launched in France and it has attracted several donations from High-networth invidiuals",
    image: tfs,
    link: "http://tfs-foundation.emergingafricagroup.com",
  },
  {
    title: "Fundall Mobile App",
    category: "mobile",
    description:
      "The Fundall app is a product that offers banking services that covers GROWING money, SPENDING money and BORROWING money.",
    image: fundall_mobile,
    link: "https://apps.apple.com/ng/app/fundall/id1545242036",
  },
  {
    title: "EA Advisory",
    category: "web",
    description:
      "The Emerging Africa Advisory is an investment platform that sets to bridge the gap between different kinds of investors and companies intending to raise funds. This products caters for debt capital raise and equity capital raise (venture capital/private equity).",
    image: ea_advisory,
    link: "https://eacadvisory.emergingafricagroup.com/",
  },
  {
    title: "Potters Choice",
    category: "web",
    description:
      "The potters choice platform was designed to cater for buyers, sellers and dealers. Also, the product includes features like car finance and automations with whatsapp to enhance easier communication with users of the platform.",
    image: potters_choice,
    link: "https://potterschoice.vercel.app/",
  },
];
