import { Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <Flex
      h="100vh"
      w="100vw"
      mx="auto"
      direction="column"
      justify="center"
      align="center"
    >
      <Text mb="20px">Seems you got lost</Text>
      <Link to="/" style={{ textDecoration: "underline" }}>
        Go back Home
      </Link>
    </Flex>
  );
};
