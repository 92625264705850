import { Box, Flex, Grid, GridItem, Link, Text } from "@chakra-ui/react";
import fundall from "assets/images/fundall_avi.png";
import { FundallProjects } from "components/Projects/Fundall/fundall-projects";

export const Fundall = () => {
  return (
    <Box as="section">
      <Flex justify="center" align="center" mb="30px">
        <Box mr="10px">
          <img src={fundall} alt="Fundall" />
        </Box>
        <Box as="span" color="black" fontWeight="700" fontSize="32px">
          Fundall
        </Box>
      </Flex>

      <Grid
        rowGap="60px"
        columnGap="30px"
        gridTemplateColumns={{
          base: "1fr",
          lg: "1fr 1fr",
          xxl: "1fr 1fr 1fr",
        }}
      >
        {FundallProjects.map(
          ({ title, description, image, category, link }, i) => (
            <GridItem
              key={i}
              w="100%"
              mx="auto"
              display="flex"
              bgImage={image}
              bgSize="cover"
              bgPosition="center"
              maxW="600px"
              h="330px"
              pos="relative"
            >
              <Flex
                direction="column"
                pos="absolute"
                h="100%"
                w="100%"
                bg="#000000C4"
                justify="end"
                pl="30px"
                pb="30px"
                zIndex="2"
                opacity="0"
                transition="all 0.19s ease-in"
                _hover={{ opacity: "1" }}
              >
                <Box
                  as="span"
                  color="#FFAA54"
                  fontWeight="700"
                  fontSize="32px"
                  mb="10px"
                >
                  {title}
                </Box>

                <Text
                  color="white"
                  w={{ base: "90%", xl: "400px" }}
                  textAlign="justify"
                  fontWeight="400"
                  mb="20px"
                >
                  {description}
                </Text>

                <Box
                  as="span"
                  color="#2D9CDB"
                  fontWeight="400"
                  fontSize="16px"
                  mb="20px"
                >
                  <Link
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    textDecor="underline"
                    fontWeight="500"
                  >
                    {category === "web" ? `Visit website` : `Visit App store`}
                  </Link>
                </Box>
              </Flex>
            </GridItem>
          )
        )}
      </Grid>
    </Box>
  );
};
