import { Flex, Box, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { cases } from "components/CaseStudies/cases";

export const CaseStudies = () => {
  const navigate = useNavigate();
  return (
    <Box as="section" px={{ base: "30px", lg: "50px", xl: "180px" }}>
      <Flex justify={{ base: "center", lg: "start" }} mb="50px">
        <Box
          as="span"
          w="fit-content"
          fontWeight="500"
          color="#DBB736"
          bg="rgba(255, 229, 0, 0.08)"
          py="5px"
          px="10px"
        >
          My Top Case Studies
        </Box>
      </Flex>

      {cases.map(
        ({ title, category, year, image, description, route }, index) => {
          return (
            <Flex
              key={index}
              direction={{
                base: "column-reverse",
                lg: `${index % 2 === 0 ? "row" : "row-reverse"}`,
              }}
              justify="space-between"
              mb="150px"
              pos="relative"
            >
              <Flex direction="column" align={{ base: "center", lg: "unset" }}>
                <Text mb="30px" fontWeight="600">
                  0{index + 1}
                </Text>

                <Flex align="center" mb="25px">
                  <Text fontWeight="700" fontSize="32px">
                    {title}
                  </Text>
                </Flex>

                <Text fontWeight="500" mb="10px">
                  {category} | {year}
                </Text>

                <Text w={{ base: "auto", lg: "500px" }} mb="70px">
                  {description}
                </Text>

                {title === "FirstBillsPay" ? (
                  <Button
                    bg="#BDBDBD2E"
                    color="#A1A1A1"
                    w="180px"
                    h="50px"
                    border="1px dashed #BDBDBD"
                    fontWeight="400"
                    _hover={{ bg: "#BDBDBD2E" }}
                  >
                    Work in Progress
                  </Button>
                ) : (
                  <Button
                    variant="main"
                    w="180px"
                    h="50px"
                    className="skewed-button"
                    onClick={() => navigate(`/case-study/${route}`)}
                  >
                    <Box as="span" className="straight-text">
                      View Case Study
                    </Box>
                  </Button>
                )}
              </Flex>

              <Box
                display={{ base: "none", xl: "block" }}
                bg="white"
                pos="absolute"
                w="60%"
                h="350px"
                right={index % 2 === 0 ? "0" : "auto"}
                left={index % 2 !== 0 ? "0" : "auto"}
                zIndex="-1"
                transform={index % 2 !== 0 ? "skewX(8deg)" : "skewX(-8deg)"}
              />

              <Box
                h={{ base: "auto", xl: "480px" }}
                transition="all 0.29s ease-in-out"
                mb={{ base: "50px", xl: "0px" }}
                pr={{ base: "0", xl: index % 2 !== 0 ? "20px" : "0" }}
                transform={{
                  base: "none",
                  xl: `perspective(1000px) rotateX(10deg) rotateY(-20deg) scale3d(1, 1, 1) ${
                    index % 2 !== 0 ? "translateX(-100px)" : "translateX(0px)"
                  }`,
                }}
                _hover={{
                  transform: `rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1) translateX(0px)`,
                }}
              >
                <img src={image} alt={title} />
              </Box>
            </Flex>
          );
        }
      )}
    </Box>
  );
};
