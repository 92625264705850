import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import "@fontsource/darker-grotesque";
import "@fontsource/darker-grotesque/500.css";
import "@fontsource/darker-grotesque/600.css";
import "@fontsource/darker-grotesque/700.css";

const config: ThemeConfig = {
  initialColorMode: "light",
  // useSystemColorMode: true,
};

const styles = {
  global: (props: any) => ({
    body: {
      color: mode("black", "#F5FAFF")(props),
      bg: mode("#FFFEFA", "#FFFEFA")(props),
    },
    Text: {
      color: mode("black", "#F5FAFF")(props),
      baseStyle: {
        letterSpacing: "0.005em !important",
      },
    },
  }),
};

const breakpoints = {
  sm: "300px",
  md: "768px",
  lg: "1024px",
  xl: "1450px",
  xxl: "1840px",
};

const theme = extendTheme({
  config,
  breakpoints,
  styles,
  fonts: {
    heading: `'Darker Grotesque', sans-serif`,
    body: `'Darker Grotesque', sans-serif`,
  },
  colors: {
    era: {
      brown: "#FFAA54",
    },
  },
  components: {
    Button: {
      baseStyle: {
        boxShadow: "none !important",
        outline: "none !important",
        borderSize: "0px",
        border: "none",
        _focus: {
          boxShadow: "none !important",
          outline: "none !important",
        },
      },
      variants: {
        main: () => ({
          fontSize: "16px",
          fontWeight: "400",
          color: "white",
          bg: "black",
          borderRadius: "5px",
          transform: "skewX(20deg)",
          transition: "all 0.3s ease-in-out",
          _hover: {
            transform: "skewX(-20deg)",
          },
          _active: {
            bg: "black !important",
          },
        }),
        clear: () => ({
          fontSize: "16px",
          fontWeight: "400",
          color: "black",
          bg: "transparent",
          border: "1px solid black",
          borderRadius: "5px",
          transform: "skewX(20deg)",
          transition: "all 0.3s ease-in-out",
          _hover: {
            transform: "skewX(-20deg)",
            bg: "#0000000F",
          },
          _active: {
            bg: "rgba(0, 0, 0, 0.06) !important",
          },
        }),
        ghost: () => ({
          _hover: {
            bg: "transparent",
          },
        }),
      },
    },
    // CircularProgress: {
    //   baseStyle: {
    //     filledTrack: {
    //       bg: "red",
    //     },
    //   },
    // },
  },
});

export default theme;
