import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";
import { companies } from "components/Companies/companies";
import link from "assets/icons/link.svg";
import { useNavigate } from "react-router-dom";

export const Companies = () => {
  const navigate = useNavigate();
  return (
    <Flex as="section" direction="column" mb="150px">
      <Box mb="50px" mx={{ base: "auto", lg: "0" }}>
        <Text
          as="span"
          w="fit-content"
          fontWeight="500"
          color="#DBB736"
          bg="rgba(255, 229, 0, 0.08)"
          py="5px"
          px="10px"
          mb="50px"
        >
          Companies I’ve worked with
        </Text>
      </Box>

      <Flex justify="space-between" direction={{ base: "column", lg: "row" }}>
        {companies.map(
          (
            {
              name,
              image,
              description,
              role,
              year,
              fontColor,
              site_URL,
              site_Link,
              projects_link,
            },
            index
          ) => (
            <Flex
              direction="column"
              key={index}
              align={{ base: "center", lg: "unset" }}
              mb={{ base: "120px", lg: "0" }}
            >
              <Box as="span" fontWeight="500">
                0{index + 1}
              </Box>

              <Flex
                align="center"
                mt="30px"
                direction={{ base: "column", lg: "row" }}
              >
                <Box mr="10px">
                  <img src={image} alt={name} />
                </Box>
                <Box color={fontColor} fontSize="32px" fontWeight="700">
                  {name}
                </Box>

                <Flex ml={{ base: "0", lg: "20px" }}>
                  <img src={link} alt="link" />
                  <Link
                    href={site_URL}
                    target="_blank"
                    color="#009FD9"
                    ml="10px"
                    textDecoration="underline"
                  >
                    {site_Link}
                  </Link>
                </Flex>
              </Flex>

              <Flex align="center" fontWeight="500" mt="20px">
                <Box as="span" mr="5px">
                  {role}
                </Box>{" "}
                |{" "}
                <Box as="span" ml="5px">
                  {year}
                </Box>
              </Flex>

              <Text my="20px" maxW="500px" textAlign="justify">
                {description}
              </Text>

              <Button
                variant="main"
                w="180px"
                h="50px"
                mt="auto"
                className="skewed-button"
                onClick={() => navigate(projects_link)}
              >
                <Box as="span" className="straight-text">
                  View Projects
                </Box>
              </Button>
            </Flex>
          )
        )}
      </Flex>
    </Flex>
  );
};
