import fbp from "assets/images/fbp_case-study.png";
import ea from "assets/images/ea_case-study.png";
import fundall from "assets/images/fundall_case-study.png";

interface Case {
  title: string;
  description: string;
  image: string;
  category: string;
  year: string;
  route: string;
}

export const cases: Case[] = [
  {
    title: "Fundall",
    category: "Product Design",
    year: "2022",
    description:
      "The Fundall app is a product that offers banking services that covers GROWING money, SPENDING money and BORROWING money. This product currently serves over 40,000 users.",
    image: fundall,
    route: "fundall-mobile-app",
  },
  {
    title: "EA Advisory",
    category: "Product Design",
    year: "2022",
    description:
      "The Emerging Africa Advisory is an investment platform that sets to bridge the gap between different kinds of investors and companies intending to raise funds. This products caters for debt capital raise and equity capital raise (venture capital/private equity).",
    image: ea,
    route: "ea-advisory",
  },
  {
    title: "FirstBillsPay",
    category: "Product Design",
    year: "2022",
    description:
      "The Firstbillspay platform is a bills payment and collection platform designed to help users carryout easy and fast financial transactions.",
    image: fbp,
    route: "",
  },
];
