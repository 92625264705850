import fbp from "assets/images/firstbillspay.png";
import fc from "assets/images/first_checkout.png";

interface FBNProject {
  title: string;
  description: string;
  image: string;
  link: string;
}

export const FBNProjects: FBNProject[] = [
  {
    title: "FisrtBillsPay",
    description:
      "The Firstbillspay platform is a bills payment and collection platform designed to help users carryout easy and fast financial transactions",
    image: fbp,
    link: "https://firstbillspay.com",
  },
  {
    title: "FirstCheckout",
    description:
      "The Firstchekout platform is a payment gateway platform which was desinged to help merchants integrate Firstbank’s payment services (like USSD, QR, CARDS and PHONE NUMBER) into their system and receive payments from cutomers with ease.",
    image: fc,
    link: "https://firstcheckout.com",
  },
];
