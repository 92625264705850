interface Review {
  name: string;
  review: string;
}

export const TopRowReviews: Review[] = [
  {
    name: "Wola Joseph",
    review:
      "A true financial partner. I have been able to access free digital banking features that help me fund my lifestyle goals and meet my needs.",
  },
  {
    name: "Izabela Glab",
    review:
      "It is really smooth. I like that I can hide my balance and create a Financial guide. Really cool!",
  },
  {
    name: "Grace Obaeze",
    review:
      "The app is easy to use, friendly and welcoming—plus it helps manage money.",
  },
];

export const BottomRowReviews: Review[] = [
  {
    name: "Joseph Uzo.",
    review:
      "The app is amazing... You can even request money from family and friends and remind them if they forget.",
  },
  {
    name: "Paul Ebegbe",
    review:
      "Fundall has helped me monitor my investments. Before I started using Fundall, there was no platform for me to see on a daily basis how much my investments are yielding. Well done, Fundall.",
  },
  {
    name: " Nifemi Akin-Obembe",
    review:
      "I really love the app and the fact that i can activate it on my phone without going through a very annoying and long process is amazing, plus the app is very colourful. Not to forget Ari is also friendly.",
  },
];
