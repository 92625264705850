import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Footer } from "components/footer";
import { Header } from "components/Header/header";
import { LandingPage } from "components/landing-page";
import { About } from "./pages/about";
import { Home } from "./pages/home";
import { NotFound } from "./pages/not-found";
import { Projects } from "./pages/projects";
import { Flex } from "@chakra-ui/react";
import { Fundall } from "components/Projects/Fundall";
import { FirstBank } from "components/Projects/FBN";
import { FundallMobileApp } from "pages/case-study/fundall-mobile-app";
import { EAAdvisory } from "pages/case-study/ea-advisory";
import { CustomCursor } from "components/CustomCursor";

export const App = () => {
  const [progress, setProgress] = useState(20);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (progress < 100) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 20);
      }, 400);

      return () => {
        clearInterval(timer);
      };
    }
  }, [progress]);

  useEffect(() => {
    if (progress === 100) {
      setIsLoaded(true);
    }
  }, [progress]);

  if (!isLoaded) {
    return <LandingPage progress={progress} />;
  }

  return (
    <>
      <CustomCursor />
      <Header />

      <Flex
        as="main"
        direction="column"
        pos="relative"
        top="84px"
        overflowY="auto"
        overflowX="hidden"
      >
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />}>
            <Route index element={<Fundall />} />
            <Route path="fundall" element={<Fundall />} />
            <Route path="fbn" element={<FirstBank />} />
          </Route>
          <Route
            path="/case-study/fundall-mobile-app"
            element={<FundallMobileApp />}
          />
          <Route path="/case-study/ea-advisory" element={<EAAdvisory />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Flex>

      <Footer />
    </>
  );
};
