import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import { MouseContext } from "contexts/mouseContext";
import useMousePosition from "hooks/useMousePosition";

export const CustomCursor = () => {
  const { x, y } = useMousePosition();
  const { cursorType } = useContext(MouseContext);
  return (
    <>
      <Box
        style={{ left: `${x}px`, top: `${y}px` }}
        pos="fixed"
        top="0"
        left="0"
        bg={cursorType === "hover" ? "rgba(31, 30, 30, 0.1)" : "transparent"}
        w={cursorType === "hover" ? "50px" : "22px"}
        h={cursorType === "hover" ? "50px" : "22px"}
        border="2px solid rgba(31, 30, 30, 0.808)"
        borderRadius="50%"
        transform="translate(-50%, -50%)"
        transition="all 100ms ease-out"
        willChange="width, height, transform, border"
        zIndex="999"
        pointerEvents="none"
        // mixBlendMode="difference"
      ></Box>
      <Box
        display={cursorType === "hover" ? "none" : "block"}
        style={{ left: `${x}px`, top: `${y}px` }}
        pos="fixed"
        top="50%"
        left="50%"
        w="8px"
        h="8px"
        bg="black"
        borderRadius="50%"
        transform="translate(-50%, -50%)"
        zIndex="999"
        pointerEvents="none"
        // _hover={{ display: cursorType === "hover" && "none" }}
      ></Box>
    </>
  );
};
