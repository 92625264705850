import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
} from "@chakra-ui/react";
import initial from "assets/icons/E.O.svg";
import copyright from "assets/icons/copyright.svg";

interface InitialPageLoadProps {
  progress: number;
}

export const LandingPage = ({ progress }: InitialPageLoadProps) => {
  return (
    <Flex
      direction="column"
      bg="black"
      w="100vw"
      h="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress
        size="200px"
        thickness="5px !important"
        value={progress}
        color="grey"
      >
        <CircularProgressLabel color="white" fontWeight="500" fontSize="48px">
          {progress}%
        </CircularProgressLabel>
        {/* <Box bg="red" pos="absolute" zIndex="1" w="200px" borderRadius="50%" /> */}
      </CircularProgress>
      <Flex direction="column" align="center">
        <Text
          textAlign="center"
          color="white"
          fontSize="48px"
          fontWeight="500"
          mb="30px"
          letterSpacing="0.005em"
        >
          Setting up...
        </Text>

        <Text
          w="255px"
          textAlign="center"
          color="white"
          letterSpacing="0.005em"
          fontSize="18px"
        >
          I see myself as God's gift to the world.
        </Text>
      </Flex>

      <Flex
        w="100vw"
        justify="space-between"
        pos="fixed"
        bottom="35px"
        px="50px"
      >
        <Box>
          <img src={initial} alt="Initials" />
        </Box>
        <Box>
          <Flex align="center">
            <Box>
              <img src={copyright} alt="Initials" />
            </Box>
            <Text color="white" ml="10px">
              {" "}
              2022. proudly created by Erastus, your favourite designer
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};
