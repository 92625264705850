import { Box, Flex, Text } from "@chakra-ui/react";
import Slider from "react-slick";
import { recommendations } from "components/Recommendations/recommendation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
};

export const Recommendations = () => {
  return (
    <Box as="section" px={{ base: "30px", lg: "50px", xl: "180px" }} mb="120px">
      <Text
        w="fit-content"
        color="#FF6242"
        bg="rgba(255, 98, 66, 0.08)"
        py="5px"
        px="10px"
        mb="50px"
      >
        Recommendations
      </Text>

      <Box>
        <Slider {...settings}>
          {recommendations.map(
            ({ name, position, company, image, content }, index) => {
              return (
                <Flex
                  direction="column"
                  align="center"
                  key={index}
                  textAlign="center"
                >
                  <Text
                    mb="30px"
                    fontSize="18px"
                    w={{ base: "auto", lg: "550px" }}
                    mx="auto"
                  >
                    "{content}"
                  </Text>

                  <Text mb="10px" fontWeight="600" textAlign="center">
                    {name}
                  </Text>

                  <Text mb="10px" color="#A5A5A5" textAlign="center">
                    {position}, {company}
                  </Text>

                  <Flex mx="auto" w="fit-content" mb="20px">
                    <img src={image} alt={company} />
                  </Flex>
                </Flex>
              );
            }
          )}
        </Slider>
      </Box>
    </Box>
  );
};
