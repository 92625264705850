import { Box, Button, Flex, Link, Text, Textarea } from "@chakra-ui/react";
import instagram from "assets/icons/instagram.svg";
import linkedin from "assets/icons/linkedin.svg";
import twitter from "assets/icons/twitter.svg";
import copyright from "assets/icons/copyright.svg";
import eo from "assets/images/eo-watermark.png";

export const Footer = () => {
  return (
    <Flex
      as="footer"
      pos="relative"
      direction="column"
      mt="50px"
      bg="black"
      color="white"
      px={{ base: "10px", lg: "100px", xl: "230px" }}
      pt="100px"
    >
      <Flex
        justify="space-between"
        mb="30px"
        pos="relative"
        zIndex="1"
        direction={{ base: "column", lg: "row" }}
      >
        <Flex
          as="section"
          direction="column"
          align={{ base: "center", lg: "initial" }}
          mb={{ base: "50px", lg: "0" }}
        >
          <Text mb="10px" fontWeight="500" fontSize="32px">
            Connect with Erastus
          </Text>
          <Text mb="40px" opacity="0.5">
            You can always reach out on any platforms, I’m friendly
          </Text>

          <Flex>
            <a href="http://www.instagram.com/erastus_o" target='_blank' rel="noreferrer">
              <Box mr="20px">
                <img src={instagram} alt="Instagram" />
              </Box>
            </a>
            <a href="https://www.linkedin.com/in/jesutofunmi-oluwatosin-870955209" target='_blank' rel="noreferrer">
              <Box mr="20px">
                <img src={linkedin} alt="LinkedIn" />
              </Box>
            </a>
            <a href="http://www.twitter.com/erastus_o" target='_blank' rel="noreferrer">
              <Box>
                <img src={twitter} alt="Twitter" />
              </Box>
            </a>
          </Flex>
        </Flex>

        <Flex as="section" direction="column">
          <Text
            fontSize="32px"
            mb="10px"
            textAlign={{ base: "center", lg: "start" }}
          >
            Leave a{" "}
            <Text as="span" fontWeight="500">
              Message
            </Text>
          </Text>

          <Textarea
            size="lg"
            mb="30px"
            mx={{ base: "auto", lg: "0" }}
            w={{ base: "75%", lg: "400px", xl: "500px" }}
            h="180px"
            color="white"
            bg="#1D1D1D"
            border="none"
            outline="none"
            placeholder="Type something"
            _placeholder={{ color: "white", opacity: "0.5" }}
          />

          <Button
            variant="main"
            w="180px"
            h="50px"
            className="skewed-button"
            mx={{ base: "auto", lg: "0" }}
          >
            <Box as="span" className="straight-text">
              Submit
            </Box>
          </Button>
        </Flex>
      </Flex>

      <Flex my="30px" justify="center" pos="relative" zIndex="1">
        <Flex align="center">
          <Box display={{ base: "none", lg: "block" }}>
            <img src={copyright} alt="Initials" />
          </Box>
          <Text
            color="white"
            ml="10px"
            textAlign={{ base: "center", lg: "start" }}
          >
            {" "}
            2022. Proudly created by Erastus, your favourite designer.
            Implemented by{" "}
            <Link href="https://www.linkedin.com/in/emmanuel-okwuzi-945b14224/" target='_blank' rel="noreferrer">
              KuzmanTheDev
            </Link>
          </Text>
        </Flex>
      </Flex>

      <Flex
        pos="absolute"
        right="0"
        top={{ base: "unset", lg: "0" }}
        bottom={{ base: "0", lg: "unset" }}
      >
        <img src={eo} alt="EO watermark" />
      </Flex>
    </Flex>
  );
};
