import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import ea from "assets/images/ea-hero.png";
import solution from "assets/images/ea-solution.png";
import approach from "assets/images/ea-approach.png";
import homepage from "assets/images/eaa-homepage.png";
import listing from "assets/images/ea-listing.png";
import opportunities from "assets/images/ea-opportunities.png";
import upload_deal from "assets/images/ea-upload-deal.png";
import bulk_mail from "assets/images/ea-bulk-email.png";

export const EAAdvisory = () => {
  const [show, setShow] = useState(false);
  const [source, setSource] = useState("");

  const handleVideo = (src: string) => {
    setSource(src);
    setShow(true);
  };

  return (
    <Flex
      as="section"
      direction="column"
      mx={{ base: "10px", md: "50px", xl: "180px" }}
      pt="50px"
    >
      <Flex
        as="section"
        direction={{ base: "column-reverse", md: "row" }}
        bg="linear-gradient(358.66deg, rgba(255, 170, 84, 0.58) -47.78%, rgba(255, 255, 255, 0) 98.38%)"
        h="40vh"
        pos="relative"
      >
        <Flex
          direction="column"
          justify="end"
          pl={{ base: "10px", lg: "40px" }}
          pb="40px"
          h="100%"
        >
          <Box fontSize={{ base: "30px", md: "44px", xl: "48px" }}>
            Emerging Africa Capital{" "}
            <Box as="span" fontWeight="500">
              Advisory
            </Box>
          </Box>
          <Text w={{ base: "90%", lg: "450px" }}>
            This project was delivered by the corporate services team of Fundall
          </Text>
        </Flex>

        <Flex
          pos="absolute"
          justify={{ base: "center", lg: "unset" }}
          right={{ base: "unset", md: "20px" }}
          w={{ base: "100%", lg: "auto" }}
          h={{ base: "65%", md: "70%", lg: "100%" }}
          p="20px"
          top={{ base: "0", md: "unset" }}
          zIndex="-1"
        >
          <img src={ea} alt="Emerging Africa" />
        </Flex>
      </Flex>

      <Flex
        mt="80px"
        justify="space-between"
        mb="60px"
        borderBottom="1px solid #E4E4E4"
        pb="70px"
        flexWrap="wrap"
        rowGap="30px"
      >
        <Flex direction="column" mr={{ base: "30px", md: "0" }}>
          <Box as="span" fontWeight="700">
            MY ROLE
          </Box>
          <Box as="span">Product Designer</Box>
        </Flex>

        <Flex direction="column" mr={{ base: "30px", md: "0" }}>
          <Box as="span" fontWeight="700">
            TOOLS
          </Box>
          <Box as="span">Figma</Box>
        </Flex>

        <Flex direction="column">
          <Box as="span" fontWeight="700">
            LINK
          </Box>
          <Link
            href="https://eacadvisory.emergingafricagroup.com/"
            target="_blank"
            color="#009FD9"
            textDecor="underline"
          >
            eacadvisory.emergingafricagroup.com
          </Link>
        </Flex>
      </Flex>

      <Flex
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
        mb={{ base: "150px", lg: "100px" }}
      >
        <Box
          w={{ base: "100%", lg: "45%" }}
          px={{ base: "10px", lg: "0" }}
          mb={{ base: "50px", lg: "0" }}
        >
          <Flex
            as="span"
            w="fit-content"
            fontWeight="500"
            color="#DBB736"
            bg="rgba(255, 229, 0, 0.08)"
            py="5px"
            px="10px"
            mx={{ base: "auto", lg: "0" }}
          >
            OVERVIEW
          </Flex>

          <Box mt="20px">
            <Text mb="20px">
              Emerging Capital Advisory is the investment banking arm of the
              Emerging Africa Group. The firm specializes in generating funds
              for both private and public organizations. The firm has assisted
              noteworthy companies in raising funds to carry out multi-million
              naira projects.
            </Text>

            <Text>
              The corporation wanted to expand their services with technology
              since it would allow them to access markets outside of their
              borders. The idea was to create a single platform that would allow
              investors to analyze investable deals while also providing firms
              with infinite financial resources to improve their operations.
            </Text>
          </Box>
        </Box>

        <Box w={{ base: "100%", lg: "45%" }} px={{ base: "10px", lg: "0" }}>
          <Flex
            as="span"
            w="fit-content"
            fontWeight="500"
            color="#DBB736"
            bg="rgba(255, 229, 0, 0.08)"
            py="5px"
            px="10px"
            mx={{ base: "auto", lg: "0" }}
          >
            WORKING WITH TEAM
          </Flex>

          <Box mt="20px">
            <Text mb="20px">
              While working on this project, I led sessions that helped me
              understand the client's daily transaction process. This helped me
              understand how a transaction is carried out.
            </Text>

            <Text>
              I was also in charge of utilizing user experience to create an
              intuitive interface for the product's intended market.
            </Text>
          </Box>
        </Box>
      </Flex>

      <Flex
        direction={{ base: "column-reverse", lg: "row" }}
        justify="space-between"
        mb="100px"
      >
        <Box w={{ base: "100%", lg: "45%" }}>
          <Flex
            as="span"
            w="fit-content"
            fontWeight="500"
            color="#DBB736"
            bg="rgba(255, 229, 0, 0.08)"
            py="5px"
            px="10px"
            mx={{ base: "auto", lg: "0" }}
            pos="relative"
            top={{ base: "-320px", md: "-350px", lg: "unset" }}
          >
            APPROACH
          </Flex>

          <Box mt="20px" px={{ base: "10px", lg: "0" }} textAlign="justify">
            <Text mb="20px">
              Project delivery timescales in an agile team are generally 2-3
              weeks, necessitating a rapid approach to solution delivery. This
              inspired us to use the{" "}
              <Box as="span" fontWeight="600">
                "DOUBLE DIAMOND METHOD"
              </Box>{" "}
              which has only four crucial milestones to project completion.
            </Text>
            <Text>
              This strategy allowed us to thoroughly investigate the main issue
              and focus on taking the proper step to assist our client.
            </Text>
          </Box>
        </Box>

        <Flex
          w={{ base: "100%", lg: "45%" }}
          justify={{ base: "center", lg: "unset" }}
        >
          <img src={approach} alt="Approach" />
        </Flex>
      </Flex>

      <Box as="section">
        <Flex
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          mb="100px"
        >
          <Box
            w={{ base: "100%", lg: "45%" }}
            px={{ base: "10px", lg: "0" }}
            textAlign="justify"
          >
            <Flex
              as="span"
              w="fit-content"
              fontWeight="500"
              color="#DBB736"
              bg="rgba(255, 229, 0, 0.08)"
              py="5px"
              px="10px"
              mx={{ base: "auto", lg: "0" }}
            >
              DISCOVERY
            </Flex>

            <Text my="20px">
              The Discovery workshop was crucial, particularly for team members
              with little prior experience in investment banking.
            </Text>

            <Text>
              At this stage, we organized meetings with the customer in order to
              understand the{" "}
              <Box as="span" fontWeight="600">
                why of the solution,
              </Box>{" "}
              <Box as="span" fontWeight="600">
                the types of people they serve, their work process, and any
                challenges that arise while working.
              </Box>
            </Text>
          </Box>

          <Flex w={{ base: "100%", lg: "45%" }} direction="column">
            <Box
              my={{ base: "20px", lg: "0px" }}
              mb={{ lg: "10px" }}
              pl={{ base: "10px", lg: "0" }}
            >
              Key feedbacks we got from the client include:
            </Box>

            <Flex
              direction={{ base: "column", lg: "row" }}
              align={{ base: "start", lg: "center" }}
              mb="30px"
              px={{ base: "10px", lg: "0" }}
            >
              <Box whiteSpace="nowrap" mr="10px" mb={{ base: "10px", lg: "0" }}>
                Target audience:
              </Box>

              <Box fontWeight="700" bg="#FFF9EC" py="10px" px="20px">
                “In all engagements, we work with high networth Individuals,
                Startups and Organisations”
              </Box>
            </Flex>

            <Flex
              direction={{ base: "column", lg: "row" }}
              align={{ base: "start", lg: "center" }}
              px={{ base: "10px", lg: "0" }}
            >
              <Box whiteSpace="nowrap" mr="10px" mb={{ base: "10px", lg: "0" }}>
                Major painpoint:
              </Box>

              <Box fontWeight="700" bg="#FFF9EC" py="10px" px="20px">
                “Our main struggle is having to send mails to investors one
                after the other which takes up to 5 hours to accomplish. We need
                to reduce that time so we can focus on other deliverables”
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          mb="100px"
        >
          <Box
            w={{ base: "100%", lg: "45%" }}
            px={{ base: "10px", lg: "0" }}
            textAlign="justify"
          >
            <Flex
              as="span"
              w="fit-content"
              fontWeight="500"
              color="#DBB736"
              bg="rgba(255, 229, 0, 0.08)"
              py="5px"
              px="10px"
              mx={{ base: "auto", lg: "0" }}
            >
              DEFINING
            </Flex>

            <Text my="20px">
              Given the high degree of technological adoption by Nigerian
              enterprises, most companies/startups in Nigeria are seeking funds
              from investors in order to realize their business goals and
              objectives.
            </Text>

            <Text>
              However, there is a large gap between these potential investors
              and the featured firms.
            </Text>
          </Box>

          <Flex
            w={{ base: "100%", lg: "45%" }}
            direction="column"
            px={{ base: "10px", lg: "0" }}
          >
            <Flex
              direction={{ base: "column", lg: "row" }}
              align={{ base: "start", lg: "center" }}
              mb="30px"
            >
              <Box whiteSpace="nowrap" mr="10px" my={{ base: "10px", lg: "0" }}>
                For the Investor:
              </Box>

              <Box fontWeight="700" bg="#FFF9EC" py="10px" px="20px">
                Sourcing for the right investment opportunity that would
                generate positive returns has been a hassle for investors and
                this comes with critical analysis and consideration
              </Box>
            </Flex>

            <Flex
              direction={{ base: "column", lg: "row" }}
              align={{ base: "start", lg: "center" }}
            >
              <Box
                w={{ base: "auto", lg: "100px" }}
                mr="10px"
                mb={{ base: "10px", lg: "0" }}
              >
                For the Companies/Startup :
              </Box>

              <Box fontWeight="700" bg="#FFF9EC" py="10px" px="20px">
                While worrying about how funds can be raised to finance
                activities, sourcing for investors can be another hassle
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Box>

      <Flex direction="column" as="section" mb="120px">
        <Flex
          as="span"
          w="fit-content"
          fontWeight="500"
          color="#DBB736"
          bg="rgba(255, 229, 0, 0.08)"
          py="5px"
          px="10px"
          mx={{ base: "auto", lg: "0" }}
        >
          SOLUTION
        </Flex>

        <Flex direction={{ base: "column-reverse", lg: "column" }}>
          <Flex mt="20px" direction="column" px={{ base: "10px", lg: "0" }}>
            <Text mb="20px">
              We came at a feasible solution that worked in accordance with the
              client's deal engagement process after going through difficulties
              and determining who we were designing for. The procedure consisted
              of three phases, which were as follows:
            </Text>

            <Box as="ol" pl="20px">
              <Box as="li" mb="10px">
                If the firm meets the main requirements, the engagement and deal
                structuring will be organized between the company and the
                emerging africa transaction team.
              </Box>
              <Box as="li" mb="10px">
                Once stage 1 is finished, Emerging Africa will submit the deal
                to the marketplace for investors to view.
              </Box>
              <Box as="li" mb="10px">
                This is the part where an investor is interested in a deal and
                may easily express their interest. At this time, investors can
                invest in lucrative companies recommended by the developing
                Africa advisory team.
              </Box>
            </Box>
          </Flex>

          <Flex my="40px">
            <img src={solution} alt="Solution" />
          </Flex>
        </Flex>
      </Flex>

      <Box mb="120px">
        <Flex
          as="span"
          w="fit-content"
          fontWeight="500"
          color="#DBB736"
          bg="rgba(255, 229, 0, 0.08)"
          py="5px"
          px="10px"
          mx={{ base: "auto", lg: "0" }}
        >
          DEVELOPING THE PRODUCT
        </Flex>

        <Flex
          direction={{ base: "column-reverse", lg: "row" }}
          mt="20px"
          mb={{ base: "180px", lg: "120px" }}
          justify="space-between"
        >
          <Box w={{ base: "100%", lg: "45%" }}>
            <Text mb="10px">
              Sketching out user journeys, brainstorming concepts, and building
              information architecture were all part of the product development
              process. These actions assist us in developing an intuitive and
              all-inclusive solution for the product.
            </Text>

            <Link
              href="https://eacadvisory.emergingafricagroup.com/"
              target="_blank"
              color="#009FD9"
              fontWeight="600"
              textDecor="underline"
            >
              View website
            </Link>
          </Box>

          <Flex
            w={{ base: "100%", lg: "48%" }}
            justify={{ base: "center", lg: "unset" }}
            my={{ base: "30px", lg: "0" }}
          >
            <img src={homepage} alt="Homepage" />
          </Flex>
        </Flex>

        <Flex
          direction={{ base: "column-reverse", lg: "row" }}
          justify="space-between"
          mb={{ base: "190px", lg: "100px" }}
        >
          <Box w={{ base: "100%", lg: "45%" }}>
            <Box
              fontWeight="700"
              pos="relative"
              fontSize={{ base: "24px", lg: "32px" }}
              top={{ base: "-340px", md: "-450px", lg: "unset" }}
              textAlign={{ base: "center", lg: "unset" }}
              my="10px"
            >
              Applying for listing by companies
            </Box>

            <Box px={{ base: "10px", lg: "0" }}>
              <Text>
                Companies who seek funding through the platform are expected to
                apply to be listed. This program assists the advisory team in
                determining whether companies have the ability to provide
                favorable returns to investors. This due research and monitoring
                decreases the investor's risk of investing in the incorrect
                firm.
              </Text>

              <Button
                variant="ghost"
                textDecor="underline"
                color="#2D9CDB"
                p="0"
                onClick={() =>
                  handleVideo(
                    "https://res.cloudinary.com/du0ibmdsx/video/upload/v1670608939/Applying_to_get_listed_jgqcda.mov"
                  )
                }
              >
                View flow here
              </Button>
            </Box>
          </Box>

          <Flex
            w={{ base: "100%", lg: "48%" }}
            justify={{ base: "center", lg: "unset" }}
          >
            <img src={listing} alt="Homepage" />
          </Flex>
        </Flex>

        <Flex
          mb={{ base: "180px", lg: "100px" }}
          direction={{ base: "column-reverse", lg: "row" }}
          justify="space-between"
        >
          <Box w={{ base: "100%", lg: "45%" }}>
            <Box
              fontWeight="700"
              my="10px"
              pos="relative"
              fontSize={{ base: "24px", lg: "32px" }}
              top={{ base: "-380px", md: "-500px", lg: "unset" }}
              textAlign={{ base: "center", lg: "unset" }}
            >
              Viewing & Investing in a deal by an investor
            </Box>

            <Box
              pos="relative"
              top={{ base: "-60px", md: "-5px" }}
              px={{ base: "10px", lg: "0" }}
            >
              <Text textAlign="justify">
                Investors with direct access to the site can search through
                projects including debt/equity capital raises, venture capital
                raises, and private equity. Additionally, investors can express
                their interest in a certain venture.
              </Text>

              <Button
                variant="ghost"
                textDecor="underline"
                color="#2D9CDB"
                p="0"
                onClick={() =>
                  handleVideo(
                    "https://res.cloudinary.com/du0ibmdsx/video/upload/v1670609055/Viewing_a_deal_by_an_investor_rzxroe.mov"
                  )
                }
              >
                View flow here
              </Button>
            </Box>
          </Box>

          <Flex
            w={{ base: "100%", lg: "48%" }}
            justify={{ base: "center", lg: "unset" }}
          >
            <img src={opportunities} alt="Homepage" />
          </Flex>
        </Flex>

        <Flex
          direction={{ base: "column-reverse", lg: "row" }}
          mb={{ base: "150px", lg: "100px" }}
          justify="space-between"
        >
          <Box w={{ base: "100%", lg: "45%" }}>
            <Box
              fontWeight="700"
              pos="relative"
              fontSize={{ base: "24px", lg: "32px" }}
              top={{ base: "-360px", md: "-450px", lg: "unset" }}
              textAlign={{ base: "center", lg: "unset" }}
              my="10px"
            >
              Uploading a deal by a rep of the advisory team
            </Box>

            <Box
              pos="relative"
              top={{ base: "-60px", md: "-5px" }}
              px={{ base: "10px", lg: "0" }}
            >
              <Text>
                After the advisory team has reviewed a firm and all transaction
                engagements have been completed, a representative of the
                advisory team uploads the deal so investors may access it and
                express interest.
              </Text>
              <Button
                variant="ghost"
                textDecor="underline"
                color="#2D9CDB"
                p="0"
                onClick={() =>
                  handleVideo(
                    "https://res.cloudinary.com/du0ibmdsx/video/upload/v1670608990/Uploading_a_deal_nwnqfa.mov"
                  )
                }
              >
                View flow here
              </Button>
            </Box>
          </Box>

          <Flex
            w={{ base: "100%", lg: "48%" }}
            justify={{ base: "center", lg: "unset" }}
          >
            <img src={upload_deal} alt="Homepage" />
          </Flex>
        </Flex>

        <Flex
          mb="50px"
          direction={{ base: "column-reverse", lg: "row" }}
          justify="space-between"
        >
          <Box w={{ base: "100%", lg: "45%" }}>
            <Box
              fontWeight="700"
              pos="relative"
              fontSize={{ base: "24px", lg: "32px" }}
              top={{ base: "-340px", md: "-450px", lg: "unset" }}
              textAlign={{ base: "center", lg: "unset" }}
              my="10px"
            >
              Sending a mail to multiple investors
            </Box>

            <Text mb="20px">
              Sending emails to investors was a major pain in the a$$ for the
              advisory team considering the number of hours it took to round up
              all mails.
            </Text>

            <Box mb="20px">
              <Box as="span" fontWeight="600">
                Why was this hard?
              </Box>
              <Text>
                Ordinarily, it would have been easy to copy every other investor
                in the message but the pdf attachement was customised to every
                investor and the total number of investor was more than 2,000.
                So sending a bulk mail was not possible.
              </Text>
            </Box>

            <Box mb="20px">
              <Box as="span" fontWeight="600">
                How did we solve this issue?
              </Box>
              <Text mb="20px">
                We designed a platform that allows the admin team send an email
                to all investors and upload custom made pdf attachment to
                multiple investors at the same.
              </Text>

              <Text>
                This helped the admin team save some time when sending emails to
                all investors.
              </Text>
            </Box>

            <Button
              variant="ghost"
              textDecor="underline"
              color="#2D9CDB"
              p="0"
              onClick={() =>
                handleVideo(
                  "https://res.cloudinary.com/du0ibmdsx/video/upload/v1670609122/Sending_mail_to_investors_xncqvq.mov"
                )
              }
            >
              View flow here
            </Button>
          </Box>

          <Flex
            w={{ base: "100%", lg: "48%" }}
            justify={{ base: "center", lg: "unset" }}
            maxH="378px"
          >
            <img src={bulk_mail} alt="Homepage" />
          </Flex>
        </Flex>
      </Box>

      <Modal isOpen={show} onClose={() => setShow(false)} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalBody>
            <video controls width="100%" style={{ maxHeight: "470px" }}>
              <source src={source} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
